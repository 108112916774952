import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { APP_SCROLLER, appURLs } from "@/app/constants.mjs";
import { TRAIT_BLACKLIST } from "@/game-tft/constants.mjs";
import Champions from "@/game-tft/UpdatesSetTenChampions.jsx";
import Traits from "@/game-tft/UpdatesSetTenTraits.jsx";
import HelpIcon from "@/inline-assets/help.svg";
import Container from "@/shared/ContentContainer.jsx";
import { MatchLoading } from "@/shared/Match.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Banner from "@/shared/SplashBanner.jsx";
import globals from "@/util/global-whitelist.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const tabs = [
  {
    key: "whatsnew",
    label: ["val:whatsNew", "What's new?"],
    Component: UpdatesWhatsNew,
  },
  {
    key: "traits",
    label: ["tft:common.traits", "Traits"],
    Component: UpdatesTraits,
  },
  {
    key: "champions",
    label: ["lol:champion_plural", "Champions"],
    Component: UpdatesChampions,
  },
];
const image = `${appURLs.CDN}/blitz/tft/set-update-page/FiveYearBash/BG_FiveYearBash.webp`;
const header = [
  "common:navigation.fiveYearBashPengusParty",
  "5 Year Bash: Pengu’s Party",
];
const title = [
  "tft:updates.fiveYearBashPengusParty",
  "TFT’s 5 Year Bash: Pengu’s Party",
];
const subTitle = ["tft:tftJune2024", "Teamfight Tactics - June 2024"];
const set = "set11";
const gap = 48; // Represents the var(--sp-12) computed container spacing
const debounce = { indexes: [], delay: 100 };

function isBottomOfContainer(target) {
  return target.scrollTop + target.clientHeight >= target.scrollHeight;
}

function Reveal({ id, timeout, children, headerOffset }) {
  const ref = useRef(null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setReady(true);
    }, timeout);
    return () => {
      clearTimeout(id);
    };
  }, [timeout]);

  useLayoutEffect(() => {
    const node = ref?.current;
    if (!node) return;

    const index = tabs.findIndex((i) => i.key === id);
    const root = globals.document.getElementsByClassName(APP_SCROLLER)[0];
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        // Intersect
        if (isIntersecting) {
          if (!debounce.indexes.some((i) => i === index)) {
            debounce.indexes.push(index);
          }
        } else {
          const indexOfIndex = debounce.indexes.findIndex((i) => i === index);
          if (index === tabs.length - 1 && debounce.indexes.length === 1) {
            // Handles the scroll event in Updates Component
            debounce.indexes.push(tabs.length - 2);
          }
          if (indexOfIndex >= 0) debounce.indexes.splice(indexOfIndex, 1);
        }
        // Handles multiple sections if we reached the bottom of the root
        if (isBottomOfContainer(root)) debounce.indexes = [tabs.length - 1];
        // Sort and navigate
        if (debounce.indexes.length > 1) debounce.indexes.sort();
      },
      {
        root,
        rootMargin: `-${headerOffset}px 0px 0px 0px`, // Only takes % or px values
      },
    );
    observer.observe(node);
    return () => {
      observer.disconnect();
    };
  }, [headerOffset, id]);
  return (
    <div id={id} ref={ref}>
      {isReady ? (
        children
      ) : (
        <LoadingContainer>
          <MatchLoading />
        </LoadingContainer>
      )}
    </div>
  );
}

function Updates() {
  const { t } = useTranslation();
  const [headerOffset, setHeaderOffset] = useState(0);

  const links = useMemo(() => {
    return tabs.map((i) => {
      return {
        text: t(...i.label),
        url: `/tft/set-${set.replace("set", "")}?section=${i.key}`,
        routeMatch: new RegExp(i.key, "i"),
        onClick(event) {
          event.preventDefault();
          const element = globals.document.getElementById(i.key);
          const main = globals.document.getElementsByClassName(APP_SCROLLER)[0];
          // Added 1px to offset IntersectionObservers intersecting range
          main.scrollTop = element.offsetTop - gap + 1;
          return false;
        },
      };
    });
  }, [t]);

  useEffect(() => {
    const view = globals.document.getElementsByClassName(APP_SCROLLER)[0];
    function scroll(event) {
      const target = event.target;
      if (isBottomOfContainer(target)) {
        debounce.indexes = [tabs.length - 1];
      }
      // This header offset number is used for IntersectionObserver's rootMargin property to accomodate for the sticky header
      const attrTarget = "data-sticky-cumulative-height";
      const stickyCumulativeHeights = Array.from(
        globals.document.querySelectorAll(`[${attrTarget}]`),
      ).map((i) => Number(i.getAttribute(attrTarget)));
      stickyCumulativeHeights.sort((a, b) => b - a);
      setHeaderOffset(stickyCumulativeHeights[0] ?? 0);
    }
    view.addEventListener("scroll", scroll);
    return () => {
      view.removeEventListener("scroll", scroll);
    };
  });

  return (
    <>
      <PageHeader title={t(...header)} links={links} />
      <Container className={containerCSS()}>
        {tabs.map((tab, idx) => {
          const Component = tab.Component;
          return (
            <Reveal
              key={idx}
              id={tab.key}
              timeout={idx * 500}
              headerOffset={headerOffset}
            >
              {idx === 0 ? (
                <Banner
                  image={image}
                  title={t(...title)}
                  subtitle={t(...subTitle)}
                  title2={
                    /* eslint-disable */
                    <div className="flex column type-subtitle">
                      <div>PBE Release: May 30th</div>
                      <div>Live Release: June 12th</div>
                    </div>
                    /* eslint-enable */
                  }
                >
                  <Component />
                </Banner>
              ) : (
                <Component />
              )}
            </Reveal>
          );
        })}
      </Container>
    </>
  );
}

export function meta() {
  return {
    title: [
      "tft:helmet.updates.title5YearBashPengusParty",
      "TFT 5 Year Bash: Pengu's Party - Patch Notes - Blitz Teamfight Tactics",
    ],
    description: [
      "tft:helmet.updates.description5YearBashPengusParty",
      "Explore TFT 5 Year Bash: Pengu's Party with returning traits and mechanics! ",
    ],
    image: {
      url: `${appURLs.CDN}/blitz/tft/set-update-page/FiveYearBash/BG_FiveYearBash.webp`,
      alt: [
        "tft:helmet.updates.title5YearBashPengusParty",
        "TFT 5 Year Bash: Pengu's Party - Patch Notes - Blitz Teamfight Tactics",
      ],
      width: 984,
      height: 555,
    },
  };
}

export default Updates;

function UpdatesWhatsNew() {
  const { t } = useTranslation();
  /* eslint-disable */
  return (
    <>
      <Section className="whatsNew">
        <h2 className="type-h4 section-title">
          {t("tft:whatsNew", "What's New")}
          <IconWrapper
            data-tip={t(
              "tft:pageIsBasedOn",
              "Page is based on data Riot provided early. Page will automatically update once more info gets revealed.",
            )}
            data-place={"bottom"}
          >
            <HelpIcon />
          </IconWrapper>
        </h2>
        <ContentBlock padding="var(--sp-8)">
          <p className="content type-body1">
            Pengu is throwing their biggest party ever, and everyone,
            including every single Set, is invited. Introducing
            <b> Pengu's Party</b>, a veritable convergence of some of TFT's most
            beloved (and feared) Traits of all time in Pengu's Party!
          </p>
          <br />
          <p className="content type-body1 ">
            Pengu's Party starts much like a normal game of TFT. You'll queue up
            with 7 other players equally excited to celebrate TFT's 5th birthday
            in the world of Inkborn Fables.
            <b>
              {" "}
              In Pengu's Party, however, you'll be able to bolster your roster
              with traits from past sets that represent all 5 years of TFT
              history!{" "}
            </b>
            The Carousel is also getting updated with some 5-Year Bash flair to
            amp up the celebration.
          </p>
          <br />
          <p className="content type-body1 ">
            Each Set will be represented by two traits completely unique to that
            Set. At various points in the game you'll be able to choose a Trait
            from set's past, with an opportunity to make one Prismatic by the
            end of the game. We've all seen Me Mech, No Scout, No Pivot, but how
            about a Me Mech, Me a Pirate, fire the cannons? Feeling social,
            elect your set superlative by placing them on the Sociliate hex from
            Gizmos & Gadgets!
          </p>
        </ContentBlock>
      </Section>
      <Section className="newMechanics">
        <h2 className="type-h4 section-title">
          {t("tft:newMechanics", "New Mechanics")}
        </h2>
        <ContentBlock className="container" padding="var(--sp-8)">
          <div className="flex">
            <img
              width="413"
              height="230"
              className="content-img"
              src={`${appURLs.CDN}/blitz/tft/set-update-page/FiveYearBash/RevivedTraits.webp`}
            />
            <div className="right">
              <h4 className="title type-h6">Revived Traits</h4>
              <p className="content type-body1 color-shade1">
                On stages 1-2 and 2-5 you can select an Anniversary Trait. Then
                on stage 3-5 upgrade one of them.
                <br />
                Anniversary Traits are based on traits from past Sets and are
                always active.
              </p>
            </div>
          </div>
          <hr />
          <div className="flex">
            <img
              width="413"
              height="230"
              className="content-img"
              src={`${appURLs.CDN}/blitz/tft/set-update-page/FiveYearBash/CupcakesAndPartyCrab.webp`}
            />
            <div className="right">
              <h4 className="title type-h6">Cupcakes and Party Crab</h4>
              <p className="content type-body1 color-shade1">
                PvE monsters are replaced by cupcakes and party crabs that grant
                extra loot.
                <br />
                You also have the Golden Item Remover for the entire game.
              </p>
            </div>
          </div>
        </ContentBlock>
      </Section>
    </>
  );
  /* eslint-enable */
}

function UpdatesTraits() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const traits = state.tft?.[set]?.traits;
  const filteredTraits =
    traits &&
    Object.fromEntries(
      Object.entries(traits).filter(
        ([, trait]) =>
          !TRAIT_BLACKLIST[set]?.includes(trait.apiKey) &&
          trait.apiKey?.includes("TFTEvent5YR"),
      ),
    );
  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:traits", "Traits")}
      </h2>
      <Traits traits={filteredTraits} set={set} t={t} />
    </Section>
  );
}

function UpdatesChampions() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const champions = state.tft[set]?.champions;
  const filteredChampions =
    champions &&
    Object.fromEntries(
      Object.entries(champions).filter(([, champ]) =>
        champ.apiKey?.includes("TFTEvent5YR"),
      ),
    );
  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:champions", "Champions")}
      </h2>
      <Champions
        champions={filteredChampions}
        set={set}
        cost={"all"}
        buttons={[]}
        isSkinsEnabled={isSkinsEnabled}
      />
    </Section>
  );
}

//STYLES
const containerCSS = () => css`
  display: grid;
  gap: var(--sp-12);
`;
const ContentBlock = styled(Card)`
  .content {
    max-width: 70ch;

    a {
      color: var(--primary);
    }
  }

  ul.content {
    margin-bottom: 1em;
    padding-left: var(--sp-8);
    list-style-type: initial;
  }

  img {
    max-width: 100%;
    filter: brightness(1.1);
  }

  video {
    border-radius: var(--br);
    &:hover {
      cursor: pointer;
    }
  }

  .vsc-controller {
    display: none;
  }

  .content-img {
    max-width: 85%;

    ${mobile} {
      max-width: 100%;
    }
  }

  .flex {
    display: flex;

    ${mobile} {
      flex-direction: column;
    }
  }

  .horizontal-tiles {
    display: flex;
    flex-direction: row;
    gap: var(--sp-10);

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: flex-end;
      gap: var(--sp-4);
    }

    hr {
      border: var(--sp-0_5) solid var(--shade6);
      border-right: none;
    }

    ${mobile} {
      flex-direction: column;

      hr {
        width: 100%;
      }
    }
  }
`;

const IconWrapper = styled("div")`
  display: inline-block;
  cursor: pointer;
  margin: 0 var(--sp-2);

  svg {
    width: var(--sp-6);
    height: var(--sp-6);
    fill: var(--shade3);
  }
`;

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);

  &.newMechanics {
    margin-top: var(--sp-12);

    .container {
      gap: var(--sp-6);
      display: flex;
      flex-direction: column;
    }

    .flex {
      justify-content: space-around;
      align-items: center;
    }

    .right {
      max-width: 330px;

      .title {
        margin-bottom: var(--sp-2);
      }
    }

    hr {
      border: var(--sp-px) solid var(--shade6);
      width: 100%;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchPortals {
      width: 100%;
      max-width: 18ch;
    }

    ${mobile} {
      flex-direction: column;
      align-items: flex-start;

      label {
        width: 100%;
        margin-top: var(--sp-4);
      }
    }
  }

  .leveling-changes {
    list-style: disc;
    padding-left: var(--sp-6);
  }

  ${mobile} {
    &.whatsNew {
      margin-top: 200px;
    }

    .button-group {
      flex-wrap: wrap;
    }
  }
`;

// Not the same one used in game-lol or game-tft, just shadowed the name
const LoadingContainer = styled("div", forwardRef)`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeMore = styled("div")`
  width: 100%;
  text-align: center;
  color: var(--shade1);
  background: var(--shade7);
  padding: var(--sp-2) 0;
  border-radius: 0 0 var(--br) var(--br);
  &:hover {
    color: var(--shade0);
    background: var(--shade6);
    cursor: pointer;
  }

  ${mobile} {
    width: 100%;
  }
`;
